import type { HostContext } from '@guideguide/core/src/lib/context'
import type { WritableAtom } from 'nanostores'
import '../../styles/web-guideguide/interface-manager.scss'
import { debounce } from 'debounce'
import {
  MouseEventHandler,
  Ref,
  useEffect,
  useRef,
  createRef,
  useState,
} from 'react'
import { useStore } from '@nanostores/react'
import {
  Fauxtoshop,
  fauxtoshopStore,
} from '@guideguide/website/src/lib/state/fauxtoshop-store'
import getBoundingClientRect from '@guideguide/website/src/lib/getBoundingClientRect'
import isInside from '@guideguide/website/src/lib/is-inside'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import type { Guide } from '@guideguide/shared/src/lib/guide'
import { slugifyGuide } from '@guideguide/shared/src/lib/guide'

interface Props {
  className?: string
  hostContextStore: WritableAtom<HostContext>
  onInteraction?: () => void
}

type TransitionableGuide = Guide & {
  id: string
  nodeRef: Ref<HTMLSpanElement>
}

type TransitionableSelection =
  | (Exclude<Fauxtoshop['selection'], null> & {
      nodeRef: Ref<HTMLSpanElement>
    })
  | null

const InterfaceManager = ({
  className,
  hostContextStore,
  onInteraction,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const mouseStart = useRef<[number, number] | null>(null)
  const mouseLastSeen = useRef<[number, number] | null>(null)
  const hostContext = useStore(hostContextStore)
  const fauxtoshop = useStore(fauxtoshopStore)
  const [fireMouseMove, setFireMouseMove] = useState(false)
  const [mouseShouldMoveSelection, setMouseShouldMoveSelection] =
    useState(false)
  const [guides, setGuides] = useState<TransitionableGuide[]>([])
  const [selection, setSelection] = useState<TransitionableSelection>(null)
  const selectionRef = useRef<HTMLSpanElement | null>(null)

  //////////////////////////////////////////////////////////////////////
  // Guide Manager                                                    //
  //                                                                  //
  // This keeps a separate store of guides so that the animations can //
  // be independent of direct changes to the guide list               //
  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const lookup: { [id: string]: TransitionableGuide | undefined } =
      guides.reduce((a, x) => ({ ...a, [x.id]: x }), {})

    const guideObjects: TransitionableGuide[] = fauxtoshop.guides.map(
      ({ orientation, location }) => {
        const id = slugifyGuide({ orientation, location })
        return (
          lookup[id] ?? {
            id,
            location,
            orientation,
            nodeRef: createRef(),
          }
        )
      }
    )

    setGuides(guideObjects)
  }, [fauxtoshop.guides])

  //////////////////////////////////////////////////////////////////////
  // Selection Manager                                                //
  //                                                                  //
  // This keeps a separate store for selection so that the animations //
  // can be independent of direct changes           //
  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    switch (true) {
      case selection === null && fauxtoshop.selection === null:
        setSelection(null)
        break
      case selection !== null && fauxtoshop.selection === null:
        // Selection is being removed
        setSelection(null)
        break
      case selection === null && fauxtoshop.selection !== null:
        // selection is being created
        if (fauxtoshop.selection === null) {
          throw new Error('Fauxtoshop selection cannot be null')
        }
        const newSelection: TransitionableSelection = {
          ...fauxtoshop.selection,
          nodeRef: createRef(),
        }
        setSelection(newSelection)
        break
      default:
        if (fauxtoshop.selection === null || selection === null) {
          throw new Error('Fauxtoshop selection cannot be null')
        }
        setSelection({ ...selection, ...fauxtoshop.selection })
    }
  }, [fauxtoshop.selection])

  //////////////////////////////////////////////////////////////////////
  // Resize manager                                                   //
  //                                                                  //
  // This updates the fauxtoshop context whenever the window changes  //
  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!ref.current) return

    const handleResize = async () => {
      if (!ref.current) return

      const { width, height } = await getBoundingClientRect(ref.current)

      if (
        width !== fauxtoshop.document?.width ||
        height !== fauxtoshop.document.height
      ) {
        fauxtoshopStore.set({
          ...fauxtoshop,
          guides: [],
          document: { width, height },
          selection: null,
        })
      }
    }

    const onResize = debounce(handleResize, 200, true)

    window.addEventListener('resize', onResize)

    if (hostContext.document === null) {
      handleResize()
    }

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [ref.current, hostContext])

  //////////////////////////////////////////////////////////////////////
  // Context manager                                                  //
  //                                                                  //
  // This keeps the context that GuideGuide pulls in up to date with  //
  // the Fauxtoshop context                                           //
  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!fauxtoshop.document) return

    // Figure out which guides are inside the document or selection
    const { selection } = fauxtoshop
    const offsetX = selection ? selection.x : 0
    const offsetY = selection ? selection.y : 0
    const guidesInBounds = fauxtoshop.guides
      .filter(({ orientation, location }) => {
        return selection === null
          ? true
          : orientation === 'v'
          ? location >= selection.x && location <= selection.x + selection.width
          : location >= selection.y &&
            location <= selection.y + selection.height
      })
      .map(({ orientation, location }) => {
        return {
          orientation,
          location: location - (orientation === 'v' ? offsetX : offsetY),
        }
      })

    hostContextStore.set({
      ...hostContext,
      document: {
        resolution: 72,
        ruler: 'px',
        guides: guidesInBounds,
        width: (selection ? selection.width : fauxtoshop.document.width) - 1,
        height: (selection ? selection.height : fauxtoshop.document.height) - 1,
      },
    })
  }, [fauxtoshop])

  const onMouseDown: MouseEventHandler = async event => {
    onInteraction && onInteraction()
    const rect = await getBoundingClientRect(event.currentTarget)
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    mouseStart.current = [x, y]
    mouseLastSeen.current = [x, y]

    const fauxtoshop = fauxtoshopStore.get()

    if (fauxtoshop.selection && isInside([x, y], fauxtoshop.selection)) {
      setMouseShouldMoveSelection(true)
    } else {
      fauxtoshopStore.set({ ...fauxtoshop, selection: null })
    }

    setFireMouseMove(true)
  }

  const onMouseUp: MouseEventHandler = () => {
    mouseStart.current = null
    mouseLastSeen.current = null
    setMouseShouldMoveSelection(false)
    setFireMouseMove(false)
  }

  const onMouseMoveCreateSelection: MouseEventHandler = async event => {
    if (!mouseStart.current || !fireMouseMove) return
    const rect = await getBoundingClientRect(event.currentTarget)
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    if (!mouseStart.current) return
    fauxtoshopStore.set({
      ...fauxtoshop,
      selection: {
        x: Math.min(x, mouseStart.current[0]),
        y: Math.min(y, mouseStart.current[1]),
        width: Math.abs(x - mouseStart.current[0]),
        height: Math.abs(y - mouseStart.current[1]),
        animate: false,
      },
    })
  }

  const onMouseMoveMoveSelection: MouseEventHandler = async event => {
    if (!mouseLastSeen.current || !fireMouseMove) return
    const rect = await getBoundingClientRect(event.currentTarget)
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    const offsetX = x - mouseLastSeen.current[0]
    const offsetY = y - mouseLastSeen.current[1]

    if (!mouseLastSeen.current || !fauxtoshop.selection) return
    let newSelection = {
      ...fauxtoshop.selection,
      x: fauxtoshop.selection.x + offsetX,
      y: fauxtoshop.selection.y + offsetY,
    }

    if (newSelection.x < 0) newSelection.x = 0
    if (newSelection.y < 0) newSelection.y = 0
    if (newSelection.x + newSelection.width > rect.right - rect.left) {
      newSelection.x = rect.right - rect.left - newSelection.width
    }
    if (newSelection.y + newSelection.height > rect.bottom - rect.top) {
      newSelection.y = rect.bottom - rect.top - newSelection.height
    }

    fauxtoshopStore.set({
      ...fauxtoshop,
      selection: newSelection,
    })

    mouseLastSeen.current = [x, y]
  }

  const onMouseEnter: MouseEventHandler = () => {
    document.querySelector('body')!.onmouseup = null
    if (!mouseStart.current) return null
    setFireMouseMove(true)
    setMouseShouldMoveSelection(true)
  }

  const onMouseLeave: MouseEventHandler = async event => {
    if (!mouseStart.current || !fireMouseMove) return
    setMouseShouldMoveSelection(false)
    setFireMouseMove(false)

    if (!mouseShouldMoveSelection) {
      const rect = await getBoundingClientRect(event.currentTarget)
      let x = event.clientX - rect.left
      let y = event.clientY - rect.top

      if (x < 0) x = 0
      if (x >= rect.right - rect.left) x = rect.right - rect.left - 1
      if (y < 0) y = 0
      if (y >= rect.bottom - rect.top) y = rect.bottom - rect.top - 1

      if (!mouseStart.current) return
      fauxtoshopStore.set({
        ...fauxtoshop,
        selection: {
          x: Math.min(x, mouseStart.current[0]),
          y: Math.min(y, mouseStart.current[1]),
          width: Math.abs(x - mouseStart.current[0]),
          height: Math.abs(y - mouseStart.current[1]),
          animate: false,
        },
      })
    }

    document.querySelector('body')!.onmouseup = () => {
      mouseStart.current = null
      mouseLastSeen.current = null
      document.querySelector('body')!.onmouseup = null
    }
  }

  return (
    <div
      ref={ref}
      className={`interface-manager ${className ?? ''}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={
        fireMouseMove
          ? mouseShouldMoveSelection
            ? onMouseMoveMoveSelection
            : onMouseMoveCreateSelection
          : undefined
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {hostContext.document && (
        <>
          <CSSTransition
            in={!!selection}
            nodeRef={selectionRef}
            timeout={500}
            className={`interface-manager-selection${
              selection?.animate ? ' animate' : ''
            }`}
            style={
              selection
                ? {
                    left: selection.x,
                    top: selection.y,
                    width: selection.width,
                    height: selection.height,
                  }
                : undefined
            }
          >
            <span ref={selectionRef} />
          </CSSTransition>
          {fauxtoshop.guidesAreVisible && (
            <TransitionGroup component={null}>
              {guides.map(x => (
                <CSSTransition
                  key={x.id}
                  nodeRef={x.nodeRef}
                  timeout={1000}
                  className={`interface-manager-guide ${x.orientation}`}
                  style={{
                    left: x.orientation === 'v' ? x.location : undefined,
                    top: x.orientation === 'h' ? x.location : undefined,
                  }}
                >
                  <span ref={x.nodeRef} />
                </CSSTransition>
              ))}
            </TransitionGroup>
          )}
        </>
      )}
    </div>
  )
}

export default InterfaceManager
