type Type = (
  coords: [number, number],
  rect: {
    x: number
    y: number
    height: number
    width: number
  }
) => boolean

const isInside: Type = ([x, y], rect) => {
  return (
    x > rect.x &&
    x < rect.x + rect.width &&
    y > rect.y &&
    y < rect.y + rect.height
  )
}

export default isInside
