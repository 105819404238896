import { hostContextStore } from '@guideguide/website/src/lib/state/host-context-store'
import InterfaceManager from './interface-manager'
import { stopDemo } from '@guideguide/website/src/lib/demo/scripted-demo'

const HeroInterfaceManager = () => {
  return (
    <InterfaceManager
      hostContextStore={hostContextStore}
      onInteraction={stopDemo}
    />
  )
}

export default HeroInterfaceManager
